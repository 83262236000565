<template>
  <v-overlay :value="show">
    <v-card
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <h3>Заполнение доп.кода по правилам</h3>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <div class="white pa-5 rounded-0">
        <div>
          <extra-tnved-rules
            v-model="rules"
            :options="options"
          />
        </div>
        <div class="d-flex justify-end mt-4">
          <v-btn
            color="#5CB7B1"
            elevation="0"
            darks
            @click="submit"
          >
            Применить
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import ExtraTnvedRules from '@/components/documents/catalogs/extra-tnved-rules.vue'
import {mapGetters} from "vuex";

export default {
  components: {
    ExtraTnvedRules
  },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      rules: []
    }
  },
  computed: {
    ...mapGetters({
      selected: "statistics/getSelected",
      options: "statistics/getExtraTnvedOptions"
    })
  },
  methods: {
    preparedRules(rules) {
      return rules.map(rule => {
        const {first, second, third, fourth} = rule
        return {
          name_11: first, name_12: second, name_13: third, name_14: fourth
        }
      })
    },
    submit() {
      this.$store.dispatch('statistics/addExtraTnvedRules', {
        declarationId: this.selected.id,
        rules: this.preparedRules(this.rules)
      }).then(() => {
        this.$success('Готово')
        this.close()
      })
        .catch(() => this.$error())
    },
    close() {
      this.$emit('update:show', false)
    }

  }
}
</script>
